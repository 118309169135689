import get from 'lodash.get';
import React from 'react';
import { IconsList } from '../../types/shared';

import {
  Administrator,
  Announcement,
  BusinessAddress,
  CalendarEvents,
  Car,
  CarHandover,
  Catalogue,
  Caution,
  Charging,
  ChargingStation,
  Consumption,
  Distance,
  DocumentPDF,
  Documents,
  Editorial,
  ElectricRange,
  ExternalLink,
  Forward,
  FuelStation,
  Gift,
  Hotline,
  Idea,
  Login,
  Merchandise,
  Mileage,
  News,
  Notepad,
  Polls,
  Power,
  Profile,
  Projects,
  ShoppingBasket,
  Tour,
  TrafficSignRecognitionAlert,
  User,
  VoiceCall1,
  Wallbox,
  Warranty,
} from '../Icon';

const iconsList: IconsList = {
  Announcement,
  ElectricRange,
  ExternalLink,
  ChargingStation,
  Charging,
  Wallbox,
  Caution,
  Gift,
  Hotline,
  User,
  Merchandise,
  VoiceCall1,
  ShoppingBasket,
  Warranty,
  Administrator,
  CalendarEvents,
  BusinessAddress,
  Car,
  CarHandover,
  Catalogue,
  Mileage,
  Notepad,
  Profile,
  Projects,
  Tour,
  Consumption,
  Distance,
  Documents,
  Editorial,
  FuelStation,
  Idea,
  Login,
  News,
  Polls,
  Power,
  TrafficSignRecognitionAlert,
  DocumentPDF,
  Forward,
};

type CardIconProps = {
  stroke: string;
  testid: string;
  iconName: keyof IconsList;
};

export const CardIcon: React.FC<CardIconProps> = ({ stroke, iconName, testid }) => {
  const Icon = get(iconsList, iconName, null);
  if (Icon === null) {
    return null;
  }
  return <Icon stroke={stroke} data-testid={testid} />;
};
