import * as React from 'react';

export const Caution = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Caution Small</title>
    <path
      d="M21.5 20.5h-20l10-18 10 18Zm-10-4.5v2m0-10v6"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
